import React, { useState } from "react";
import allIcons from "simple-icons";
import { v4 } from "uuid";
import { IconCloud } from "react-icon-cloud";

const Sphere = () => {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const handleMouseLeave = () => {
    setIsPaused(false);
  };

  const tagCanvasOptions = {
    clickToFront: 500,
    depth: 1,
    imageScale: 2,
    initial: [0.1, -0.1],
    outlineColour: "#0000",
    reverse: true,
    tooltip: "native", // null | 'div'
    tooltipDelay: 0,
    wheelZoom: false,
    dragControl: true // add this property
  };

  const iconSlugs = [
    "tensorflow",
    "typescript",
    "flask",
    "numpy",
    "windows",
    "webpack",
    "cplusplus",
    "react",
    "redis",
    "sqlite",
    "html5",
    "opencv",
    "tailwindcss",
    "nodedotjs",
    "express",
    "ubuntu",
    "nextdotjs",
    "prisma",
    "pytorch",
    "amazonaws",
    "googlecloud",
    "postgresql",
    "postman",
    "gatsby",
    "vuedotjs",
    "adobephotoshop",
    "adobepremierepro",
    "firebase",
    "circleci",
    "nginx",
    "vercel",
    "canva",
    "jest",
    "cypress",
    "docker",
    "git",
    "jira",
    "github",
    "gitlab",
    "mongodb",
    "electron",
    "visualstudiocode",
    "python",
    "figma"
  ];

  const iconTags = iconSlugs.map((slug) => ({
    id: slug,
    simpleIcon: allIcons.Get(slug)
  }));

  return (
    <div>
      <IconCloud
        key={v4()}
        id={"icon"}
        minContrastRatio={1}
        iconSize={50}
        backgroundHexColor={"#fff"}
        fallbackHexColor={"#000"}
        tags={iconTags}
        tagCanvasOptions={tagCanvasOptions}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        pause={!isPaused} // add this prop to pause the rotation
      />
    </div>
  );
};

export default Sphere;
