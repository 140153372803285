import React, {useEffect, useRef} from "react";
import {TimelineLite} from "gsap";
import { TextScramble } from '@a7sc11u/scramble';
import Sphere from "./icons";

import "./skills.css";

// const skills = [
//   `JS(ES6+), TypeScript`,
//   `Python, C++, Bash`,
//   `ReactJS, Next, Vue`,
//   `Node.js, Flask`,
//   `Tailwind, mUI, AMP`,
//   `Tensorflow, PyTorch`,
//   `Numpy, Pandas, Keras`,
//   `SQL, SQLite, MongoDB`,
//   `Git, Docker, GCP`,
//   `Web3.js, IPFS, Pinnata`,
//   `Photoshop, Premier Pro`,
// ];

const courses = [
  {
    name: `React.js`,
    cert: `https://www.hackerrank.com/certificates/d7b30b60287c`,
    company: `HackerRank`,
    date: `2020`,
  },
  {
    name: `Ruby on Rails`,
    cert: `https://www.coursera.org/account/accomplishments/certificate/WL8TBZG26SHQ`,
    company: `Coursera`,
    date: `2020`,
  },
  {
    name: `Programming the Internet of Things (IOT) Specialization`,
    cert: `https://www.coursera.org/account/accomplishments/specialization/certificate/TJKFKGG2P6LU`,
    company: `Coursera`,
    date: `2020`,
  },
  {
    name: `Fundamentals of Network Communication`,
    cert: `https://www.coursera.org/account/accomplishments/certificate/VYQJ2V4HJ53E`,
    company: `Coursera`,
    date: `2020`,
  },
  {
    name: `RESTful API with HTTP and JavaScript`,
    cert: `https://www.coursera.org/account/accomplishments/records/LYF2M4JLNR7M`,
    company: `Rhyme`,
    date: `2020`,
  },
  {
    name: `Introduction to Internet of Things`,
    cert: `http://nptel.ac.in/noc/social_cert/noc19-cs06/NPTEL19CS06S31930129191127328.jpg`,
    company: `NPTEL`,
    date: `2019`,
  },
];

const Skills = () => {
  const coursesRef = useRef([]);
  const skillsRef = useRef([]);
  const textRef = useRef(null);
  const tl = new TimelineLite({paused: true});

  useEffect(() => {
    tl.to(textRef.current, 0.6, {opacity: 1, y: 0})
      .staggerTo(coursesRef.current, 0.25, {x: 0, opacity: 1}, 0.1, `-=.3`)
      .staggerTo(skillsRef.current, 0.25, {x: 0, opacity: 1}, 0.1, `-=.4`)
      .play();
    return () => tl.clear();
  });

  return (
    <section className="skills">
      <h2 className="invisible">Skills</h2>
      <div className="skills__col">
        <p className="skills__subtitle slide-down" ref={textRef}>
          Skills can be&nbsp;taught, personality is&nbsp;inherent. I&nbsp;prefer
          to&nbsp;keep learning, continue challenging myself, and
          do&nbsp;interesting things that matter. &nbsp; [ ⚡ <b className="reveal-text">List need updates!</b> ⚡ ]
{/* 
          <br /> <br /> <br />
          <em>👉 The below list is outdated, for recent please refer to my <b>LinkedIn</b> ✨</em> */}
        </p>
        <ul className="skills__courses">
          {courses.map(({name, cert, company, date}, index) => (
            <li
              key={name}
              className={`slide-right`}
              ref={(course) => (coursesRef.current[index] = course)}>
              <p
                className="skills__name"
                dangerouslySetInnerHTML={{__html: name}}
              />
              <p className="skills__info">
               {company}&nbsp;-&nbsp;{date}&nbsp;/{" "}
                <a
                  href={cert}
                  className="skills__cert-link link"
                  target={`blank`}
                  rel="noopener noreferrer">
                  certificate
                </a>
              </p>
            </li>
          ))}
        </ul>
        {/* <br/> */}
        <TextScramble
        // className=" slide-down"
        // ref={textRef}
        speed={2}
        as = "h4"
        text="My GitHub Stats ⮞"
        />
        <img className="resizimg pointer-events-none" src="https://ghchart.rshah.org/23ebb5/neilblaze.svg" alt="GitHub Contribution Graph" /><br/><br/>
      </div>
      <div className="skills__col">
      <Sphere />
      </div>
    </section>
  );
};

export default Skills;
